import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import styled from "@emotion/styled";
import { alpha, FormControl } from "@mui/material";
import { green } from "@mui/material/colors";

const Customised = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[600],
    "&:hover": {
      backgroundColor: alpha(green[600], 0.1),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: green[600],
  },
}));
const NewSwitch = ({
  label,
  name,
  checked = false,
  disabled = false,
  switchProps = {},
  onChange,
  ...rest
}) => {
  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Customised
            checked={checked}
            disabled={disabled}
            {...switchProps}
            onChange={(e) => {
              onChange(convertToDefEventPara(name, e.target.checked));
            }}
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export default NewSwitch;
