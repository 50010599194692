import { createSlice } from "@reduxjs/toolkit";
import { REPORT_FLOW_SLICE_NAME } from "../sliceConstants";
import extraReducers from "./thunk/reportExtraReducers";
import reportThunkActions from "./thunk/reportThunk";

const initialState = {
  fetchReportDetails: {
    isLoading: false,
    data: [],
    error: [],
  },
  fetchAchievementDetails: {
    isLoading: false,
    data: [],
    error: [],
  },
  fetchFocussedSku: {
    isLoading: false,
    data: [],
    error: [],
  },
  fetchAchievementDetailsCategoryWise: {
    isLoading: false,
    data: [],
    error: [],
  },
  getTargetFilters: {
    isLoading: false,
    data: [],
    error: [],
  },
  fetchAchievementDetailsFilterWise: {
    isLoading: false,
    data: [],
    error: [],
  },
  fetchIncentives: {
    isLoading: false,
    data: [],
    error: [],
  },
  subordinates: {
    isLoading: false,
    data: [],
    error: [],
  },
};

export const reportFlowSlice = createSlice({
  name: REPORT_FLOW_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers,
});

export const reportActions = {
  ...reportFlowSlice.actions,
  ...reportThunkActions,
};

export default reportFlowSlice.reducer;
