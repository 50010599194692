import { lazy } from "react";

export const fieldForceAdminRoutes = [
  {
    Path: "/employee/*",
    Component: lazy(() => import("../container/EmployeeEngagement/routes.js")),
    meta: {
      name: "Employee Engagement",
    },
  },
  {
    Path: "/target/*",
    Component: lazy(() => import("../container/TargetEngagement/routes.js")),
    meta: {
      name: "Target Engagement",
    },
  },
  {
    Path: "/data/*",
    Component: lazy(() => import("../container/DataUploads/routes.js")),
    meta: {
      name: "Data Uploads",
    },
  },
  {
    Path: "/incentive/*",
    Component: lazy(() => import("../container/IncentiveEngagement/routes.js")),
    meta: {
      name: "Incentive Engagement",
    },
  },
  {
    Path: "/beat/*",
    Component: lazy(() => import("../container/BeatManagement/routes.js")),
    meta: {
      name: "Beat Management",
    },
  },
];
export const fieldForceJPWRoutes = [
  {
    Path: "/resources/*",
    Component: lazy(() => import("../container/ResourcesManagement/routes.js")),
    meta: {
      name: "JMDO Management",
    },
  },
];
