import React from "react";
import {
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
} from "@mui/material";

import InputMask from "react-input-mask";

export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    mask,
    pattern,
    prefix,
    suffix,
    parentStyle = {},
    strictCheck = false,
    fullWidth = false,
    maxLength,
    uppercase,
    showLabelHeading = true,
    disabled = false,
    ...rest
  } = props;

  const onChangeInput = (e) => {
    const val = e.target.value;
    // give either mask or pattern
    if (mask && val.length < mask.length) {
    } else if (val && pattern && !pattern?.test(val)) {
      if (strictCheck) {
        return;
      }
    }
    onChange(e);
  };

  return (
    <FormControl style={parentStyle} {...(error && { error: true })} fullWidth>
      {showLabelHeading && (
        <FormLabel sx={{ textAlign: "left", fontWeight: "600", mb: "12px" }}>
          {label}
        </FormLabel>
      )}
      <InputMask
        fullWidth={fullWidth}
        mask={mask}
        maskChar={""}
        value={value}
        formatChars={{
          "#": "[0-9]",
          $: "[A-Za-z]",
          "*": "[A-Za-z0-9]",
        }}
        onChange={onChangeInput}
      >
        {(inputProps) => {
          return (
            <TextField
              onChange={onChangeInput}
              variant="outlined"
              name={name}
              value={value}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{prefix}</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">{suffix}</InputAdornment>
                ),
                inputProps: {
                  maxLength: maxLength,
                  disabled: disabled,
                  style: {
                    textTransform: uppercase ? "uppercase" : "none",
                    textOverflow: "ellipsis",
                  },
                },
              }}
              {...inputProps}
              {...(error && { error: true, helperText: error })}
              {...rest}
            />
          );
        }}
      </InputMask>
    </FormControl>
  );
}
