export const validateLoginForm = (fieldValues, temp, customValues) => {
  if ("mobileNo" in fieldValues) {
    temp.mobileNo =
      fieldValues.mobileNo && fieldValues.mobileNo.length >= 10
        ? ""
        : "Enter a 10 digit mobile numer.";
  }
  return {
    errors: { ...temp },
    isValid: Object.values(temp).every((x) => x === ""),
  };
};
