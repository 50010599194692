import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";

export default function RadioGroup(props) {
  const {
    name,
    label,
    value,
    onChange,
    items,
    parentStyle = {},
    radioProps = {},
    error,
    allOption = false,
  } = props;

  return (
    <FormControl sx={parentStyle} error={error} fullWidth>
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup row name={name} value={value} onChange={onChange}>
        {allOption && (
          <FormControlLabel
            value="All"
            control={<Radio {...radioProps} />}
            label="All"
          />
        )}
        {items.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            control={<Radio {...radioProps} />}
            label={item.title}
          />
        ))}
      </MuiRadioGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
