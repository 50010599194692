import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import LoginPopup from "../container/LoginPopup";
import JPWRoutes from "../container/PrivateRoutes/jpw";
import AdminRoutes from "../container/PrivateRoutes/admin";
import {
  fieldForceAdminRoutes,
  fieldForceJPWRoutes,
} from "./fieldForceRoutes.js";
import { Navigate } from "react-router-dom";
import Tableau from "../container/Tableau";

export default function Routers() {
  const userData = JSON.parse(sessionStorage.getItem("user_details")) || {};
  const { userRole } = userData;
  const isAdmin = userRole === "SuperAdmin";

  return (
    <Suspense>
      <Routes>
        <Route element={<AdminRoutes />}>
          <Route
            exact
            path="/"
            element={
              <Navigate
                to={isAdmin ? "/employee/add" : "/resources/retailers/listing"}
              />
            }
          />
          {fieldForceAdminRoutes.map((Routes, i) => {
            return (
              <Route
                exact
                key={i}
                path={Routes.Path}
                element={
                  Routes.Component ? <Routes.Component /> : Routes.NavigateTo
                }
              />
            );
          })}
        </Route>
        <Route element={<JPWRoutes />}>
          <Route
            exact
            path="/"
            element={
              <Navigate
                to={isAdmin ? "/employee/add" : "/resources/retailers/listing"}
              />
            }
          />
          {fieldForceJPWRoutes.map((Routes, i) => {
            return (
              <Route
                exact
                key={i}
                path={Routes.Path}
                element={
                  Routes.Component ? <Routes.Component /> : Routes.NavigateTo
                }
              />
            );
          })}
        </Route>
        <Route path="/tableau" element={<Tableau />} />
        <Route path="/login" element={<LoginPopup />} />
        <Route path="*" element={<p>Page not found</p>} replace />
      </Routes>
    </Suspense>
  );
}
