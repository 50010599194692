import { createSlice } from "@reduxjs/toolkit";

import userThunkActions from "./thunk/userThunk";
import extraReducers from "./thunk/userExtraReducers";

export const USER_FLOW_SLICE_NAME = "userFlow";

const initialState = {
  login: {
    isLoading: false,
    email: "",
    loginResponse: {},
    sendOtpResponse: {},
    error: null,
  },
  loginOtpVerify: {
    isLoading: false,
    data: null,
    error: null,
  },
  user: null,
};

export const userFlowSlice = createSlice({
  name: USER_FLOW_SLICE_NAME,
  initialState,
  reducers: {
    setEmailNumber: (state, action) => {
      state.login.email = action.payload || "";
    },
    setLoginError: (state, action) => {
      state.login.error = action.payload || "";
    },
    setOtpError: (state, action) => {
      state.loginOtpVerify.error = action.payload || "";
    },
    setLoginData: (state, action) => {
      state.loginOtpVerify.data = action.payload;
    },
  },
  extraReducers,
});

export const userActions = {
  ...userFlowSlice.actions,
  ...userThunkActions,
};

export default userFlowSlice.reducer;
