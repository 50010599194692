import userThunkActions from "./userThunk";

function createExtraReducers() {
  return {
    ...sendOtp(),
    ...verifyOtp(),
  };

  function sendOtp() {
    const { pending, fulfilled, rejected } = userThunkActions.sendOtp;
    return {
      [pending]: (state) => {
        state.login.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.login.isLoading = false;
        state.login.sendOtpResponse = action.payload;
      },
      [rejected]: (state, action) => {
        state.login.isLoading = false;
        state.login.error = action.payload;
      },
    };
  }

  function verifyOtp() {
    const { pending, fulfilled, rejected } = userThunkActions.verifyOtp;
    return {
      [pending]: (state) => {
        state.loginOtpVerify.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.loginOtpVerify.isLoading = false;
        state.loginOtpVerify.data = action.payload;
        sessionStorage.setItem("user_details", JSON.stringify(action.payload));
      },
      [rejected]: (state, action) => {
        state.loginOtpVerify.isLoading = false;
        state.loginOtpVerify.error = action.payload;
      },
    };
  }
}

const userExtraReducers = createExtraReducers();
export default userExtraReducers;
