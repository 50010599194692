import * as React from "react";
import {
  CircularProgress as MuiCircularProgress,
  Box,
  Backdrop,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoaderActions } from "../../redux/slices/componentSlice/loader";

export default function Loader() {
  const dispatch = useDispatch();
  const { show } = useSelector((state) => state.loader);

  const handleClose = () => {
    dispatch(LoaderActions.hideLoader({ show: false }));
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: 9999 }}
        open={show}
        onClick={handleClose}
      >
        <Box sx={{ display: "flex" }}>
          <MuiCircularProgress />
        </Box>
      </Backdrop>
    </div>
  );
}
