import { HTTP_METHOD } from "../../../helper/constants";
import request from "../../request";

const jmdoApi = {
  getAllRetailers: (id) => {
    const url = `/v1/jmdo/${id}/retailers`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  getRetailerDetail: (payload) => {
    const { jmdoId, id } = payload;
    const url = `/v1/jmdo/${jmdoId}/retailers/${id}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  getOrders: (payload) => {
    const { jmdoId, id } = payload;
    const url = `/v1/jmdo/${jmdoId}/retailers/${id}/orders`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  getCoupons: (payload) => {
    const { jmdoId, id } = payload;
    const url = `/v1/jmdo/${jmdoId}/retailers/${id}/offers`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  getServiceHistory: (payload) => {
    const { jmdoId, id } = payload;
    const url = `/v1/jmdo/${jmdoId}/retailers/${id}/history/service`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  getRevenue: (payload) => {
    const { jmdoId, id, fromDate, toDate, prmId, duration } = payload;
    const url = `/v1/jmdo/${jmdoId}/retailers/${prmId}/revenue?fromDate=${fromDate}&toDate=${toDate}&duration=${duration}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
};

export default jmdoApi;
