import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { TOKEN_NAME } from "../../helper/constants";
import React from "react";
import { getCookie, eraseCookie } from "../../helper/utils";
import userApi from "../../services/apiService/api/userApi";
import { useNavigate } from "react-router-dom";
export const Header = () => {
  const navigate = useNavigate();
  const { name } = JSON.parse(sessionStorage.getItem("user_details")) || {};

  const logout = () => {
    userApi.logout();
    eraseCookie(TOKEN_NAME);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          {name && <h5>Welcome, {name} </h5>}
          <Box sx={{ flexGrow: 1 }}></Box>
          {name && (
            <Button onClick={logout} color="inherit">
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Typography
        variant="h6"
        component="div"
        sx={{
          alignSelf: "left",
          display: "flex",
          margin: "12px",
          fontWeight: "600",
        }}
      >
        JMD Sales Force Performance Management
      </Typography>
    </Box>
  );
};
