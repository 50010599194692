import { createSlice } from "@reduxjs/toolkit";
import { BEAT_FLOW_SLICE_NAME } from "../sliceConstants";
import extraReducers from "./thunk/beatExtraReducers";
import beatThunkActions from "./thunk/beatThunk";

const initialState = {
  retailer: {
    isLoading: false,
    data: [],
    error: [],
  },
};

export const beatFlowSlice = createSlice({
  name: BEAT_FLOW_SLICE_NAME,
  initialState,
  reducers: {
    getRetailers: (state, action) => {
      state.retailer = action.payload;
    },
  },
  extraReducers,
});

export const beatActions = {
  ...beatFlowSlice.actions,
  ...beatThunkActions,
};

export default beatFlowSlice.reducer;
