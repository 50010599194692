import * as React from "react";
import Modal from "@mui/material/Modal";
import "./style.scss";

const style = {
  bgcolor: "background.paper",
};

export default function TransitionsModal({
  handleClose,
  open,
  classname = "",
  children,
  ...rest
}) {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className={`${classname} modal-content payModal `} style={style}>
          {children}
        </div>
      </Modal>
    </div>
  );
}
