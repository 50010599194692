import React from "react";
import { Snackbar as MuiSnackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { NotificationActions } from "../../redux/slices/componentSlice/notificationSlice";

export default function ToastMessage() {
  const dispatch = useDispatch();
  const { open, message, timeOut } = useSelector((state) => state.notification);

  const handleClose = () => {
    dispatch(NotificationActions.clearNotification({ open: false }));
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <MuiSnackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={timeOut}
        message={message}
        action={action}
      />
    </div>
  );
}
