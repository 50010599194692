import { createSlice } from "@reduxjs/toolkit";
import { JMDO_FLOW_SLICE_NAME } from "../sliceConstants";
import extraReducers from "./thunk/jmdoExtraReducers";
import jmdoThunkActions from "./thunk/jmdoThunk";

const initialPaginationInfo = {
  totalPages: 0,
  totalElements: 0,
  numberOfElements: 0,
};

const initialState = {
  jmdoId: {
    isLoading: false,
    data: "",
    error: [],
  },
  retailerList: {
    isLoading: false,
    data: [],
    error: [],
  },
  retailerDetail: {
    isLoading: false,
    data: [],
    error: [],
  },
  orderList: {
    isLoading: false,
    data: [],
    error: [],
  },
  couponList: {
    isLoading: false,
    data: [],
    error: [],
  },
  serviceList: {
    isLoading: false,
    data: [],
    error: [],
  },
  revenue: {
    isLoading: false,
    data: [],
    error: [],
  },
};

export const jmdoFlowSlice = createSlice({
  name: JMDO_FLOW_SLICE_NAME,
  initialState,
  reducers: {
    resetRetailerList: (state, action) => {
      state.retailerList.data = [];
    },
    setJmdoId: (state, action) => {
      state.jmdoId.data = action.payload;
    },
  },
  extraReducers,
});

export const jmdoActions = {
  ...jmdoFlowSlice.actions,
  ...jmdoThunkActions,
};

export default jmdoFlowSlice.reducer;
