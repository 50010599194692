import targetThunkActions from "./targetThunk";

function parseCategories(data) {
  const categories = data.map((e) => {
    return { label: e, value: e };
  });
  return categories;
}

function createExtraReducers() {
  return {
    ...getCategories(),
    ...getBrands(),
    ...createTarget(),
    ...getAllTarget(),
    ...getTargetDetails(),
    ...deleteFilter(),
  };

  function getCategories() {
    const { pending, fulfilled, rejected } = targetThunkActions.getCategories;

    return {
      [pending]: (state) => {
        state.categories.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        const parsedData = JSON.parse(action?.payload?.data)?.data || [];
        const categoryData = parseCategories(parsedData);
        state.categories = {
          isLoading: false,
          error: null,
          data: categoryData,
        };
      },
      [rejected]: (state, action) => {
        state.categories = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function getBrands() {
    const { pending, fulfilled, rejected } = targetThunkActions.getBrands;
    return {
      [pending]: (state) => {
        state.brands.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        const parsedData = JSON.parse(action?.payload?.data)?.data || [];
        state.brands = {
          isLoading: false,
          error: null,
          data: parsedData,
        };
      },
      [rejected]: (state, action) => {
        state.brands = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function createTarget() {
    const { pending, fulfilled, rejected } = targetThunkActions.createTarget;
    return {
      [pending]: (state) => {
        state.createTarget.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.createTarget = {
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.createTarget = {
          isLoading: false,
          error: action?.payload?.error || "Something Went Wrong",
        };
      },
    };
  }

  function getAllTarget() {
    const { pending, fulfilled, rejected } = targetThunkActions.getAllTarget;
    return {
      [pending]: (state) => {
        state.targetList.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        let targetList = JSON.parse(action?.payload?.data)?.data;
        state.targetList = {
          isLoading: false,
          error: null,
          data: targetList?.content || [],
          totalTargets: targetList.totalPages,
        };
      },
      [rejected]: (state, action) => {
        state.targetList = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function getTargetDetails() {
    const { pending, fulfilled, rejected } =
      targetThunkActions.getTargetDetails;

    return {
      [pending]: (state) => {
        state.targetList.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        const targetData = JSON.parse(action?.payload?.data)?.data;
        state.targetList = {
          isLoading: false,
          error: null,
          data: [targetData] || [],
          paginationInfo: {
            totalPages: targetData?.totalPages,
          },
        };
      },
      [rejected]: (state, action) => {
        state.targetList = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function deleteFilter() {
    const { pending, fulfilled, rejected } = targetThunkActions.deleteFilter;
    return {
      [pending]: (state) => {
        state.deleteFilter.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.deleteFilter = {
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.deleteFilter = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
}

const targetkExtraReducers = createExtraReducers();
export default targetkExtraReducers;
