import { HTTP_METHOD } from "../../../helper/constants";
import request from "../../request";

const employeeApi = {
  createEmployee: (body) => {
    const url = "/sales-user/create-user";
    return request({
      method: HTTP_METHOD.POST,
      url,
      data: body,
    });
  },
  updateEmployee: (body) => {
    const url = "/sales-user/update-user";
    return request({
      method: HTTP_METHOD.PUT,
      url,
      data: body,
    });
  },
  getAllEmployees: (params) => {
    const { pageNo, pageSize } = params;
    const url = `/sales-user/getAll?pageNo=${pageNo}&pageSize=${pageSize}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  getAllManagers: ({ roleId, name }) => {
    const url = `/sales-user/getAllManagers?roleId=${roleId}&name=${name}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  getAllRoles: () => {
    const url = `/sales-user/getAllRoles`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  getEmployeeData: ({ id }) => {
    const url = `/sales-user/sales-force-users?&id=${id}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  uploadBulkEmployeeCSV: (body) => {
    const url = `sales-user/excel-user-create`;
    return request({
      method: HTTP_METHOD.POST,
      responseType: "blob",
      url,
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  uploadBulkRetailerCSV: (body) => {
    const url = `/retailer-mapping/excel-upload`;
    return request({
      method: HTTP_METHOD.POST,
      responseType: "blob",
      url,
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getSearch: (params) => {
    const { pageNo, pageSize, body } = params;
    const url = `/sales-user/search?pageNo=${pageNo}&pageSize=${pageSize}`;
    return request({
      method: HTTP_METHOD.POST,
      url,
      data: body,
    });
  },
  downloadEmployeeDetails: (params) => {
    const url = `/sales-user/downloadReport`;
    return request({
      method: HTTP_METHOD.POST,
      responseType: "blob",
      url,
      data: params,
    });
  },
  removeEmployee: (empId) => {
    const url = `/sales-user/delete?employeeId=${empId}`;
    return request({
      method: HTTP_METHOD.DELETE,
      url,
    });
  },
};

export default employeeApi;
