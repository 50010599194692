import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../../../../services/apiService";
import { EMPLOYEE_FLOW_SLICE_NAME } from "../../sliceConstants";

const employeeThunkActions = {
  createEmployee: createAsyncThunk(
    `${EMPLOYEE_FLOW_SLICE_NAME}/createEmployee`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.createEmployee(body);

        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  updateEmployee: createAsyncThunk(
    `${EMPLOYEE_FLOW_SLICE_NAME}/updateEmployee`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.updateEmployee(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getAllEmployees: createAsyncThunk(
    `${EMPLOYEE_FLOW_SLICE_NAME}/getAllEmployees`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getAllEmployees(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getEmployeeData: createAsyncThunk(
    `${EMPLOYEE_FLOW_SLICE_NAME}/getEmployeeData`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getEmployeeData(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  uploadBulkEmployeeCSV: createAsyncThunk(
    `${EMPLOYEE_FLOW_SLICE_NAME}/uploadBulkEmployeeCSV`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.uploadBulkEmployeeCSV(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  uploadBulkRetailerCSV: createAsyncThunk(
    `${EMPLOYEE_FLOW_SLICE_NAME}/uploadBulkRetailerCSV`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.uploadBulkRetailerCSV(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getAllManagers: createAsyncThunk(
    `${EMPLOYEE_FLOW_SLICE_NAME}/getAllManagers`,
    async (params, { rejectWithValue }) => {
      try {
        const res = await apiService.getAllManagers(params);
        const dt = JSON.parse(res.data)?.data || [];
        return dt || [];
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getAllRoles: createAsyncThunk(
    `${EMPLOYEE_FLOW_SLICE_NAME}/getAllRoles`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await apiService.getAllRoles();
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getSearch: createAsyncThunk(
    `${EMPLOYEE_FLOW_SLICE_NAME}/getSearch`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getSearch(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
};

export default employeeThunkActions;
