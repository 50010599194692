import { createSlice } from "@reduxjs/toolkit";

export const LOADER_SLICE_NAME = "loader";

export const loaderInitialState = {
  show: false,
};

export const LoaderSlice = createSlice({
  name: LOADER_SLICE_NAME,
  initialState: loaderInitialState,
  reducers: {
    showLoader: (_state, action) => ({
      ...loaderInitialState,
      show: action.payload,
    }),
    hideLoader: (state) => ({ ...state, show: false }),
  },
});
export const LoaderActions = LoaderSlice.actions;

export default LoaderSlice.reducer;
