import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export default function Select({
  name,
  label,
  value,
  error = null,
  onChange,
  options,
  parentStyle = {},
  multiple = false,
  type = "default",
  allSelection = false,
  placeholder = "Select",
  ...rest
}) {
  const [allSelected, setAllselected] = useState("");
  const getSelectedValue = (value) => {
    if (value?.includes("Select All")) {
      return "All Selected";
    }
    if (value !== "") {
      if (Array.isArray(value)) {
        return value.join(",");
      } else return value;
    } else return <Placeholder>{placeholder}</Placeholder>;
  };

  const handleChange = (e) => {
    const { value } = e?.target || {};
    let obj = [];
    if (multiple) {
      if (!allSelected) {
        obj =
          options.filter((r) => value.includes(r.label)).map((e) => e.label) ||
          [];
      } else {
        obj = options.map((e) => e.value);
      }
    } else {
      obj = options.filter((r) => r.value === value)[0];
    }
    onChange(e, obj);
  };

  useEffect(() => {
    if (allSelection) {
      const e = {};
      e.target = {};
      e.target.name = name;
      if (allSelected === true) {
        e.target.value = options.map((e) => e.label);
        handleChange(e);
      } else if (allSelected === false) {
        e.target.value = [];
        handleChange(e);
      }
    }
  }, [allSelected]);

  return (
    <FormControl
      variant="outlined"
      {...(error && { error: true })}
      style={{ parentStyle }}
      fullWidth
    >
      {label && (
        <FormLabel sx={{ textAlign: "left", fontWeight: "600", mb: "12px" }}>
          {label}
        </FormLabel>
      )}
      <MuiSelect
        displayEmpty
        label={null}
        name={name}
        multiple={multiple}
        value={value}
        // renderValue={(selected) => getSelectedValue(selected)}
        placeholder={placeholder}
        sx={{ height: "30px" }}
        {...rest}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        {!multiple && !allSelection && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {allSelection && (
          <MenuItem onClick={() => setAllselected(!allSelected)}>
            <>
              <Checkbox checked={allSelected} />
              <ListItemText primary="Select All" />
            </>
          </MenuItem>
        )}
        {options?.map((item, index) => {
          return (
            <MenuItem
              disabled={item?.disabled}
              key={item.id + "item.label" + index}
              value={item.value}
              data-extraValue={item}
            >
              {type === "checkbox" && (
                <>
                  <Checkbox
                    checked={item.value.indexOf(value) > -1 && value.length}
                  />
                  <ListItemText primary={item.value} />
                </>
              )}
              {type === "default" && item.label}{" "}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
