import employeeThunkActions from "./employeeThunk";

function createExtraReducers() {
  return {
    ...createEmployee(),
    ...getAllEmployees(),
    ...getEmployeeData(),
    ...uploadBulkEmployeeCSV(),
    ...uploadBulkRetailerCSV(),
    ...updateEmployee(),
    ...getAllManagers(),
    ...getAllRoles(),
    ...getSearch(),
  };

  function createEmployee() {
    const { pending, fulfilled, rejected } =
      employeeThunkActions.createEmployee;
    return {
      [pending]: (state) => {
        state.createEmployee.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.createEmployee = {
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.createEmployee = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
        };
      },
    };
  }
  function updateEmployee() {
    const { pending, fulfilled, rejected } =
      employeeThunkActions.updateEmployee;
    return {
      [pending]: (state) => {
        state.updateEmployee.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.updateEmployee = {
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.updateEmployee = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
        };
      },
    };
  }
  function getAllEmployees() {
    const { pending, fulfilled, rejected } =
      employeeThunkActions.getAllEmployees;

    return {
      [pending]: (state) => {
        state.employeeList.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        let empData = JSON.parse(action?.payload?.data)?.data;
        state.employeeList = {
          isLoading: false,
          error: null,
          data: empData || [],
          paginationInfo: {
            totalPages: JSON.parse(action?.payload?.data).data.pagination
              .totalPages,
          },
        };
      },
      [rejected]: (state, action) => {
        state.employeeList = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
  function getAllManagers() {
    const { pending, fulfilled, rejected } =
      employeeThunkActions.getAllManagers;

    return {
      [pending]: (state) => {
        state.managerList.isLoading = true;
      },

      [fulfilled]: function (state, action) {
        const parsedManagerResponse = action?.payload.map((manager) => {
          return {
            label: manager.name,
            id: manager.employeeId,
          };
        });

        state.managerList = {
          ...state.managerList,
          isLoading: false,
          error: null,
          data: {
            managerData: action?.payload,
            managerRoles: parsedManagerResponse,
          },
        };
      },
      [rejected]: (state, action) => {
        state.managerList = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
  function getAllRoles() {
    const { pending, fulfilled, rejected } = employeeThunkActions.getAllRoles;

    return {
      [pending]: (state) => {
        state.managerRoles.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.managerRoles = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload?.data)?.data || [],
        };
      },
      [rejected]: (state, action) => {
        state.managerRoles = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function getEmployeeData() {
    const { pending, fulfilled, rejected } =
      employeeThunkActions.getEmployeeData;
    return {
      [pending]: (state) => {
        state.currentEmployeeData.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.currentEmployeeData = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload?.data)?.data || [],
        };
      },
      [rejected]: (state, action) => {
        state.currentEmployeeData = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
  function uploadBulkEmployeeCSV() {
    const { pending, fulfilled, rejected } =
      employeeThunkActions.uploadBulkEmployeeCSV;
    return {
      [pending]: (state) => {
        state.uploadBulkEmployeeData.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.uploadBulkEmployeeData = {
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.uploadBulkEmployeeData = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
        };
      },
    };
  }
  function uploadBulkRetailerCSV() {
    const { pending, fulfilled, rejected } =
      employeeThunkActions.uploadBulkRetailerCSV;
    return {
      [pending]: (state) => {
        state.uploadBulkRetailerData.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.uploadBulkRetailerData = {
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.uploadBulkRetailerData = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
        };
      },
    };
  }
  function getSearch() {
    const { pending, fulfilled, rejected } = employeeThunkActions.getSearch;
    return {
      [pending]: (state) => {
        state.employeeList.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.employeeList = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload?.data)?.data || [],
          paginationInfo: {
            totalPages: JSON.parse(action?.payload?.data)?.data?.pagination
              ?.totalPages,
          },
        };
      },
      [rejected]: (state, action) => {
        state.employeeList = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
}

const employeeExtraReducers = createExtraReducers();
export default employeeExtraReducers;
