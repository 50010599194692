import { HTTP_METHOD } from "../../../helper/constants";
import request from "../../request";

const userApi = {
  sendOtp: ({ mobile }) => {
    const url = "/otp/send";
    return request({
      method: HTTP_METHOD.POST,
      url,
      data: { mobile },
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  verifyOtp: ({ requestId, otp }) => {
    const url = "otp/verify";
    return request({
      method: HTTP_METHOD.POST,
      url,
      data: { requestId, otp },
    });
  },
  logout: () => {
    const url = "auth/user/logout";
    return request({
      method: HTTP_METHOD.POST,
      url,
    });
  },
};

export default userApi;
