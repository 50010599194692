import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../../../../services/apiService";
import { REPORT_FLOW_SLICE_NAME } from "../../sliceConstants";

const reportThunkActions = {
  fetchReportDetails: createAsyncThunk(
    `${REPORT_FLOW_SLICE_NAME}/fetchReportDetails`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.fetchReportDetails(body);
        return res?.data || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  fetchAchievementDetails: createAsyncThunk(
    `${REPORT_FLOW_SLICE_NAME}/fetchAchievementDetails`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.fetchAchievementDetails(body);
        return res?.data || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  fetchAchievementDetailsCategoryWise: createAsyncThunk(
    `${REPORT_FLOW_SLICE_NAME}/fetchAchievementDetailsCategoryWise`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.fetchAchievementDetailsCategoryWise(body);
        return res?.data || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getTargetFilters: createAsyncThunk(
    `${REPORT_FLOW_SLICE_NAME}/getTargetFilters`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getTargetFilters(body);
        return res?.data || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  fetchAchievementDetailsFilterWise: createAsyncThunk(
    `${REPORT_FLOW_SLICE_NAME}/fetchAchievementDetailsFilterWise`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.fetchAchievementDetailsFilterWise(body);
        return res?.data || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  fetchFocussedSku: createAsyncThunk(
    `${REPORT_FLOW_SLICE_NAME}/fetchFocussedSku`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.fetchFocussedSku(body);
        return res?.data || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  fetchIncentives: createAsyncThunk(
    `${REPORT_FLOW_SLICE_NAME}/fetchIncentives`,
    async (body, { rejectWithValue }) => {
      try {
        const res = await apiService.fetchIncentives(body);
        return res?.data || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getSubordinateFilter: createAsyncThunk(
    `${REPORT_FLOW_SLICE_NAME}/getSubordinateFilter`,
    async (id, { rejectWithValue }) => {
      try {
        const res = await apiService.getSubordinateFilter(id);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getSubordinates: createAsyncThunk(
    `${REPORT_FLOW_SLICE_NAME}/getSubordinates`,
    async (id, { rejectWithValue }) => {
      try {
        const res = await apiService.getSubordinates(id);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
};

export default reportThunkActions;
