import { HTTP_METHOD } from "../../../helper/constants";
import request from "../../request";

const reportApi = {
  fetchReportDetails: (body) => {
    const { pageSize, pageNo } = body;
    const url = `/achievement/employee?pageSize=${pageSize}&pageNo=${pageNo}`;
    return request({
      method: HTTP_METHOD.POST,
      url,
      data: body,
    });
  },
  fetchAchievementDetails: (body) => {
    const url = `/achievement/achievedTarget`;
    return request({
      method: HTTP_METHOD.POST,
      url,
      data: body,
    });
  },
  fetchFocussedSku: (body) => {
    const { jmdoId, prmId } = body;
    //const jmdoId = 60892803;
    //const prmId = 702913441;
    const url = `/v1/jmdo/${jmdoId}/retailers/${prmId}/focusedSku`;
    return request({
      method: HTTP_METHOD.GET,
      url,
    });
  },
  fetchAchievementDetailsCategoryWise: (body) => {
    const url = `/achievement/detailed`;
    return request({
      method: HTTP_METHOD.POST,
      url,
      data: body,
    });
  },
  getTargetFilters: (employeeId) => {
    const url = `/v1/achievement/filters`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      headers: { "X-userId": employeeId },
    });
  },
  fetchAchievementDetailsFilterWise: (body) => {
    const { fromDate, toDate, keys, values, employeeId } = body;
    const url = `/v1/achievement/search?fromDate=${fromDate}&toDate=${toDate}&keys=${keys}&values=${values}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      headers: { "X-userId": employeeId },
    });
  },
  fetchIncentives: (body) => {
    const url = `/incentive/target-incentives`;
    return request({
      method: HTTP_METHOD.POST,
      url,
      data: body,
    });
  },
  getSubordinateFilter: (id) => {
    const url = `sales-user/subordinates?id=${id}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  getSubordinates: (id) => {
    const url = `sales-user/jmdo?id=${id}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
};

export default reportApi;
