import * as React from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { InputLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export default function NewDatePicker({
  label,
  error,
  name,
  value,
  onChange,
  inputFormat = "DD/MM/YYYY",
  defaultValue = "",
  labelText = "",
  showLabelHeading = true,
  disableFuture = false,
  ...rest
}) {
  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {labelText && showLabelHeading && (
        <InputLabel sx={{ textAlign: "left", fontWeight: "600", mb: "12px" }}>
          {labelText}
        </InputLabel>
      )}
      <DatePicker
        disableFuture={disableFuture}
        value={value}
        label={label}
        inputFormat={inputFormat}
        onChange={(date) => onChange(convertToDefEventPara(name, date))}
        renderInput={(params) => (
          <TextField {...params} error={!!error} helperText={error} />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
}
