import beatThunkActions from "./beatThunk";

function createExtraReducers() {
  return {
    ...getRetailers(),
  };

  function getRetailers() {
    const { pending, fulfilled, rejected } = beatThunkActions.getRetailers;
    return {
      [pending]: (state) => {
        state.retailer.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.retailer = {
          isLoading: false,
          error: null,
          data: JSON.parse(action.payload.data).data,
        };
      },
      [rejected]: (state, action) => {
        state.retailer = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
}

const beatExtraReducers = createExtraReducers();
export default beatExtraReducers;
