const PAYLATER_ROUTES_PREFIX = "/paylater";

export const ROOT_ROUTE = `${PAYLATER_ROUTES_PREFIX}/dashboard`;

export const LOGIN_ROUTE = `/login`;

export const VERIFY_LOGIN_OTP = `${PAYLATER_ROUTES_PREFIX}/verify-login-otp`;

export const NEW_RETAILER_APPROVAL = `${PAYLATER_ROUTES_PREFIX}/new-approvals`;

export const BLOCKED_RETAILERS = `${PAYLATER_ROUTES_PREFIX}/blocked-retailers`;

export const UDHARI_TRANSACTION = `${PAYLATER_ROUTES_PREFIX}/udhari-transaction`;

export const VIEW_RETAILER_DETAILS = `${PAYLATER_ROUTES_PREFIX}/retailer-details/:prmId`;

export const PAGE_NOT_FOUND = `${PAYLATER_ROUTES_PREFIX}/*`;
