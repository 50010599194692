import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../../../../services/apiService/api/targetApi";
import { TARGET_FLOW_SLICE_NAME } from "../../sliceConstants";

const targetThunkActions = {
  getCategories: createAsyncThunk(
    `${TARGET_FLOW_SLICE_NAME}/getCategories`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await apiService.getCategories();
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getBrands: createAsyncThunk(
    `${TARGET_FLOW_SLICE_NAME}/getBrands`,
    async (body, { rejectWithValue }) => {
      try {
        const res = await apiService.getBrands(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  createTarget: createAsyncThunk(
    `${TARGET_FLOW_SLICE_NAME}/createTarget`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.createTarget(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  updateTarget: createAsyncThunk(
    `${TARGET_FLOW_SLICE_NAME}/updateTarget`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.updateTarget(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getAllTarget: createAsyncThunk(
    `${TARGET_FLOW_SLICE_NAME}/getAllTarget`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getAllTarget(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getTargetDetails: createAsyncThunk(
    `${TARGET_FLOW_SLICE_NAME}/getTargetDetails`,
    async (targetId, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getTargetDetails(targetId);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  uploadBulkTargetCSV: createAsyncThunk(
    `${TARGET_FLOW_SLICE_NAME}/uploadBulkTargetCSV`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.uploadBulkTargetCSV(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  deleteFilter: createAsyncThunk(
    `${TARGET_FLOW_SLICE_NAME}/deleteFilter`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.deleteFilter(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
};

export default targetThunkActions;
