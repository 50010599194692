import React from "react";
import { Tab, Tabs } from "@mui/material";

const Tableau = () => {
  const url =
    "https://rrainsights.ril.com/views/NewOnboardingDashboard/Regionwise?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link";
  return (
    <div>
      <iframe
        style={{ height: "90vh", width: "100%" }}
        src={url}
        title="W3Schools Free Online Web Tutorials"
      />
    </div>
  );
};

export default Tableau;
