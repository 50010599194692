import { HTTP_METHOD } from "../../../helper/constants";
import request from "../../request";

const beatApi = {
  getRetailers: (agentId) => {
    const url = `/retailer-mapping/getByAgentId?agentId=${agentId}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
    });
  },
};

export default beatApi;
