import React from "react";
import { useEffect, useState } from "react";
import { TOKEN_NAME, TAB_OPTIONS, USER_TAB_MAPPING } from "./helper/constants";
import { getCookie, getAccessibleTabs } from "./helper/utils";
import { useLocation } from "react-router-dom";
import "./App.css";
import "./App.scss";
import { Header } from "./container/Header";
import { CssBaseline } from "@mui/material";
import NavigationTabs from "./container/NaviagtionTabs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Controls from "./component_new/controls/Controls";
import { useSelector, useDispatch } from "react-redux";
import Routers from "./router";
import { userActions } from "./redux/slices/user/userSlice";

const theme = createTheme({
  palette: {
    primary: {
      main: "#333996",
      light: "#3c44b126",
    },
    secondary: {
      main: "#f83245",
      light: "#f8324526",
    },
    default: "#f4f5fd",
    background: {
      default: "#f4f5fd",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: "translateZ(0)",
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const [defaultTab, setDefaultTab] = useState("");
  const [tabs, setTabs] = useState([]);
  const location = useLocation();
  const { show } = useSelector((state) => state.loader);
  const isLoggedIn =
    JSON.parse(sessionStorage.getItem("user_details")) || false;
  const userData = JSON.parse(sessionStorage.getItem("user_details")) || {};
  const { userRole } = userData;

  useEffect(() => {
    dispatch(userActions.setLoginData(userData));
    if (isLoggedIn) {
      setTabs(getAccessibleTabs(userRole, TAB_OPTIONS, USER_TAB_MAPPING));
      if (window.location.href.includes("employee")) setDefaultTab("1");
      else if (window.location.href.includes("target")) setDefaultTab("2");
      else if (window.location.href.includes("beat")) setDefaultTab("3");
      else if (window.location.href.includes("reports")) setDefaultTab("4");
      else if (window.location.href.includes("tableau")) setDefaultTab("5");
      else if (window.location.href.includes("upload")) setDefaultTab("6");
    } else {
      setTabs([]);
    }
  }, [location]);

  return (
    <>
      <Controls.ToastMessage />
      {show && <Controls.Loader />}
      <ThemeProvider theme={theme}>
        <div className="App">
          {!location.pathname.includes("resources") ? (
            <>
              <div>
                <Header />
              </div>
              <div>
                <NavigationTabs items={tabs} defaultValue={defaultTab} />
              </div>
              <div className="page-wrapper">
                <Routers />
              </div>
            </>
          ) : (
            <Routers />
          )}
        </div>
        <CssBaseline />
      </ThemeProvider>
    </>
  );
}

export default App;
