import { createSlice } from "@reduxjs/toolkit";
import { EMPLOYEE_FLOW_SLICE_NAME } from "../sliceConstants";
import extraReducers from "./thunk/employeeExtraReducers";
import employeeThunkActions from "./thunk/employeeThunk";

const initialPaginationInfo = {
  totalPages: 0,
  totalElements: 0,
  numberOfElements: 0,
};

const initialState = {
  createEmployee: {
    isLoading: false,
    error: null,
  },
  updateEmployee: {
    isLoading: false,
    error: null,
    data: [],
  },
  employeeList: {
    isLoading: false,
    paginationInfo: initialPaginationInfo,
    data: [],
    error: [],
  },
  currentEmployeeData: {
    isLoading: false,
    data: [],
    error: [],
  },
  uploadBulkEmployeeData: {
    isLoading: false,
    data: [],
    error: [],
  },
  uploadBulkRetailerData: {
    isLoading: false,
    data: [],
    error: [],
  },
  managerList: {
    isLoading: false,
    data: [],
    error: [],
  },
  managerRoles: {
    isLoading: false,
    data: [],
    error: [],
  },
};

export const employeeFlowSlice = createSlice({
  name: EMPLOYEE_FLOW_SLICE_NAME,
  initialState,
  reducers: {
    retailerData: (state, action) => {
      ///  state.updateEmployee = action.payload;
    },
  },
  extraReducers,
});

export const employeeActions = {
  ...employeeFlowSlice.actions,
  ...employeeThunkActions,
};

export default employeeFlowSlice.reducer;
