import React, { useEffect, useState } from "react";
import { useForm } from "../../component_new/useForm";
import { useNavigate } from "react-router-dom";
import Controls from "../../component_new/controls/Controls";
import { LOGIN_FIELDS } from "../../helper/constants";
import { useDispatch, useSelector } from "react-redux";
import { validateLoginForm } from "../../validators/loginValidator";
import { MuiOtpInput } from "mui-one-time-password-input";
import TransitionsModal from "../../component_new/CustomTransitionModal";
import "./style.scss";
import { dispatchNotification } from "../../helper/utils";
import userThunkActions from "../../redux/slices/user/thunk/userThunk";
import { userActions } from "../../redux/slices/user/userSlice";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestId } = useSelector(
    (state) => state.userFlow.login.sendOtpResponse
  );
  const [disableMobileInput, setDisableMobileInput] = useState(true);
  const [sendOtpText, setSendOtpText] = useState("SEND OTP");
  const [otpInput, showOtp] = useState(false);
  const [otp, setOtp] = React.useState("");
  const [otpInputError, setOtpInputError] = useState("");
  const [retryTime, setRetryTime] = useState(59);
  const [timerId, setTimerId] = useState("");
  const userData = JSON.parse(sessionStorage.getItem("user_details")) || {};
  const isLoggedIn = useSelector(
    (state) => state?.userFlow?.loginOtpVerify?.data?.employeeId
  );
  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    const ans = validateLoginForm(fieldValues, temp, values);
    setErrors(ans.errors);
    return ans.isValid;
  };
  const { values, errors, setErrors, handleInputChange } = useForm(
    LOGIN_FIELDS,
    false,
    validate
  );

  const sendOtp = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      dispatch(userThunkActions.sendOtp(values.mobileNo)).then((e) => {
        if (!e.error) {
          showOtp(true);
          setRetryTime(59);
          startTimer();
          setSendOtpText("Resend OTP");
        }
      });
    }
  };

  const verifyOtp = () => {
    const payload = { requestId: requestId, otp: otp };
    if (otp.length < 6) {
      dispatchNotification({
        type: "error",
        timeout: 3000,
        message: "Enter valid otp",
      });
    } else {
      dispatch(userThunkActions.verifyOtp(payload)).then((e) => {
        if (!e.error) {
          dispatch(userActions.setLoginData(userData));
          navigate("/");
        }
      });
    }
  };

  const startTimer = () => {
    let timer = 59;
    const retryInterval = setInterval(() => {
      timer = timer - 1;
      timer = timer > 10 ? timer : "0" + timer;
      setRetryTime(timer);
    }, 1000);
    setTimerId(retryInterval);
  };

  const matchIsNumeric = (text) => {
    const empty = text === "";
    const value = parseInt(text);
    const isNumber = typeof value === "number" && !isNaN(value);
    return empty || isNumber;
  };

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  useEffect(() => {
    clearInterval(timerId);
    if (otpInput) {
      setRetryTime(59);
      startTimer();
    }
  }, [otpInput]);

  useEffect(() => {
    if (retryTime < 1) {
      clearInterval(timerId);
    }
  }, [retryTime]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <div>
      <TransitionsModal open={true} classname="popupContainer">
        <form onSubmit={sendOtp}>
          <h2>{otpInput ? "VERIFY OTP" : "LOGIN"}</h2>
          <Controls.Input
            label="Phone"
            name="mobileNo"
            variant={otpInput ? "filled" : "outlined"}
            disabled={otpInput}
            value={values.mobileNo}
            onChange={handleInputChange}
            error={errors.mobileNo}
            mask="##########"
            strictCheck={true}
          />
          {otpInput && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Controls.Button
                text="Edit phone number"
                variant="default"
                style={{
                  fontSize: "8px",
                  color: "#333996",
                }}
                onClick={() => {
                  setDisableMobileInput(false);
                  showOtp(false);
                  setSendOtpText("Send OTP");
                  setOtp("");
                }}
              />
            </div>
          )}
          {otpInput && (
            <>
              <MuiOtpInput
                className="otpInput"
                length={6}
                value={otp}
                name="otp"
                onChange={handleChange}
                validateChar={validateChar}
              />
              {otpInputError && (
                <div className="otpErrorMessage">{otpInputError}</div>
              )}
              {retryTime !== "00" && (
                <div className="retryMsg">Retry in 00:{retryTime}</div>
              )}
            </>
          )}
          <div className="actionContainer">
            <Controls.Button
              text={sendOtpText}
              style={{
                margin: "30px 0 10px 0",
                width: "100%",
              }}
              disabled={otpInput && !(otpInput && retryTime === "00")}
              type="submit"
            />
            {otpInput && (
              <Controls.Button
                text={"VERIFY OTP"}
                style={{
                  margin: "30px 0 10px 0",
                  width: "100%",
                }}
                onClick={verifyOtp}
              />
            )}
          </div>
        </form>
      </TransitionsModal>
    </div>
  );
};

export default LoginPage;
