import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { TARGET_FLOW_SLICE_NAME } from "../sliceConstants";
import extraReducers from "./thunk/targetExtraReducers";
import targetThunkActions from "./thunk/targetThunk";

const initialPaginationInfo = {
  totalPages: 0,
  totalElements: 0,
  numberOfElements: 0,
};

const initialState = {
  createTarget: {
    isLoading: false,
    data: [],
    error: [],
  },
  categories: {
    isLoading: false,
    data: [],
    error: [],
  },
  brands: {
    isLoading: false,
    data: [],
    error: [],
  },
  targetList: {
    isLoading: false,
    paginationInfo: initialPaginationInfo,
    data: [],
    error: [],
    totalTargets: "",
  },
  deleteFilter: {
    isLoading: false,
    error: [],
    data: [],
  },
  selectedCatBrands: [],
  articleCodes: [],
};

export const targetFlowSlice = createSlice({
  name: TARGET_FLOW_SLICE_NAME,
  initialState,
  reducers: {
    retailerData: (state, action) => {
      ///  state.updateEmployee = action.payload;
    },
    setSelectedBrands: (state, action) => {
      state.selectedCatBrands = action.payload;
    },
    setArticleCodes: (state, action) => {
      state.articleCodes = action.payload;
    },
  },
  extraReducers,
});

export const employeeActions = {
  ...targetFlowSlice.actions,
  ...targetThunkActions,
};

export default targetFlowSlice.reducer;
