import jmdoThunkActions from "./jmdoThunk";

function createExtraReducers() {
  return {
    ...getAllRetailers(),
    ...getFilteredRetailers(),
    ...getCoupons(),
    ...getRetailerDetail(),
    ...getServiceHistory(),
    ...getOrders(),
    ...getRevenue(),
  };

  function getAllRetailers() {
    const { pending, fulfilled, rejected } = jmdoThunkActions.getAllRetailers;

    return {
      [pending]: (state) => {
        state.retailerList.isLoading = true;
        state.retailerList.success = true;
      },
      [fulfilled]: (state, action) => {
        let retailerData = JSON.parse(action?.payload?.data);
        state.retailerList = {
          isLoading: false,
          error: null,
          data: [...state.retailerList.data, ...retailerData] || [],
        };
      },
      [rejected]: (state, action) => {
        state.retailerList = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function getFilteredRetailers() {
    const { pending, fulfilled, rejected } =
      jmdoThunkActions.getFilteredRetailers;

    return {
      [pending]: (state) => {
        state.retailerList.isLoading = true;
        state.retailerList.success = true;
      },
      [fulfilled]: (state, action) => {
        let retailerData = JSON.parse(action?.payload?.data);
        state.retailerList = {
          isLoading: false,
          error: null,
          data: retailerData || [],
        };
      },
      [rejected]: (state, action) => {
        state.retailerList = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function getRetailerDetail() {
    const { pending, fulfilled, rejected } = jmdoThunkActions.getRetailerDetail;

    return {
      [pending]: (state) => {
        state.retailerDetail.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.retailerDetail = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload?.data),
        };
      },
      [rejected]: (state, action) => {
        state.retailerDetail = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function getOrders() {
    const { pending, fulfilled, rejected } = jmdoThunkActions.getOrders;

    return {
      [pending]: (state) => {
        state.orderList.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.orderList = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload?.data),
        };
      },
      [rejected]: (state, action) => {
        state.orderList = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function getCoupons() {
    const { pending, fulfilled, rejected } = jmdoThunkActions.getCoupons;

    return {
      [pending]: (state) => {
        state.couponList.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.couponList = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload?.data),
        };
      },
      [rejected]: (state, action) => {
        state.couponList = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }

  function getServiceHistory() {
    const { pending, fulfilled, rejected } = jmdoThunkActions.getServiceHistory;

    return {
      [pending]: (state) => {
        state.serviceList.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.serviceList = {
          isLoading: false,
          error: null,
          data: JSON.parse(action?.payload?.data),
        };
      },
      [rejected]: (state, action) => {
        state.serviceList = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
  function getRevenue() {
    const { pending, fulfilled, rejected } = jmdoThunkActions.getRevenue;

    return {
      [pending]: (state) => {
        state.revenue.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        const duration = action.meta.arg.duration;
        const revenueData = JSON.parse(action?.payload?.data);
        state.revenue = {
          isLoading: false,
          error: null,
          data: { ...state.revenue.data, [duration]: revenueData } || {},
        };
      },
      [rejected]: (state, action) => {
        state.revenue = {
          isLoading: false,
          error: action.payload.error || "Something Went Wrong",
          data: [],
        };
      },
    };
  }
}

const jmdoExtraReducers = createExtraReducers();
export default jmdoExtraReducers;
