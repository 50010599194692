import { HTTP_METHOD } from "../../../helper/constants";
import request from "../../request";

const targetApi = {
  getCategories: () => {
    const url = `/category-mapping/get-categories`;
    return request({
      method: HTTP_METHOD.GET,
      url,
    });
  },
  getBrands: (categoryName) => {
    const url = `/category-mapping/getBrand?categoryName=${categoryName}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
    });
  },
  createTarget: (body) => {
    const url = `/target/create-target`;
    return request({
      method: HTTP_METHOD.POST,
      url,
      data: body,
    });
  },
  updateTarget: (body) => {
    const url = "/target/update";
    return request({
      method: HTTP_METHOD.PUT,
      url,
      data: body,
    });
  },
  getAllTarget: (params) => {
    const { pageNo, pageSize } = params;
    const url = `/target/getAllTarget?pageNo=${pageNo}&pageSize=${pageSize}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  getTargetDetails: (targetId) => {
    const url = `/target/getById?id=${targetId}`;
    return request({
      method: HTTP_METHOD.GET,
      url,
      data: {},
    });
  },
  uploadBulkTargetCSV: (body) => {
    const url = `/v1/achievement/target/upload`;
    const { file, employeeId } = body;
    return request({
      method: HTTP_METHOD.POST,
      //  responseType: "blob",
      url,
      data: file,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-userId": employeeId,
      },
    });
  },
  deleteFilter: (params) => {
    const { filterId, filterType } = params;
    const url = `/${filterType}/deleteById?id=${filterId}`;
    return request({
      method: HTTP_METHOD.DELETE,
      url,
    });
  },
};

export default targetApi;
