import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const NavigationTabs = ({
  items,
  defaultValue = "",
  handleNavigation = null,
  disabled,
  styles = {},
}) => {
  const [currentValue, setValue] = useState(defaultValue);
  const navigate = useNavigate();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event, newValue) => {
    event.preventDefault();
  };

  return (
    <Tabs
      value={currentValue}
      onChange={(e, val) => handleChange(e, val)}
      variant="scrollable"
      scrollButtons={false}
      sx={{ ...styles }}
      aria-label="scrollable prevent tabs example"
    >
      {items.map((ele) => {
        return (
          <Tab
            icon={ele.icon}
            iconPosition="start"
            label={ele.label}
            value={ele.value}
            disabled={ele.disabled}
            href={ele.link}
            onClick={(e) => {
              e.preventDefault();
              if (ele.value === currentValue) {
                return;
              }
              setValue(ele.value);
              if (handleNavigation) {
                handleNavigation(ele.link);
              } else {
                navigate(ele.link);
              }
            }}
            sx={{ fontSize: "12px", textTransform: "capitalize" }}
          />
        );
      })}
    </Tabs>
  );
};

export default NavigationTabs;
