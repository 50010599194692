import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import ToastMessage from "./ToastMessage"
import Button from "./Button";
import NewSwitch from "./Switch";
import NewDatePicker from "./DatePicker";
import Loader from "./Loader";
const Controls = {
  Input,
  RadioGroup,
  Select,
  Checkbox,
  NewSwitch,
  NewDatePicker,
  Button,
  ToastMessage,
  Loader
};

export default Controls;
