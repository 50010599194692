import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../../../../services/apiService";
import { JMDO_FLOW_SLICE_NAME } from "../../sliceConstants";

const jmdoThunkActions = {
  getAllRetailers: createAsyncThunk(
    `${JMDO_FLOW_SLICE_NAME}/getAllRetailers`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getAllRetailers(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getFilteredRetailers: createAsyncThunk(
    `${JMDO_FLOW_SLICE_NAME}/getAllFilteredRetailers`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getAllRetailers(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getRetailerDetail: createAsyncThunk(
    `${JMDO_FLOW_SLICE_NAME}/getRetailerDetail`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getRetailerDetail(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getOrders: createAsyncThunk(
    `${JMDO_FLOW_SLICE_NAME}/getOrders`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getOrders(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getCoupons: createAsyncThunk(
    `${JMDO_FLOW_SLICE_NAME}/getCoupons`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getCoupons(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
  getServiceHistory: createAsyncThunk(
    `${JMDO_FLOW_SLICE_NAME}/getServiceHistory`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getServiceHistory(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),

  getRevenue: createAsyncThunk(
    `${JMDO_FLOW_SLICE_NAME}/getRevenue`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const res = await apiService.getRevenue(body);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
};

export default jmdoThunkActions;
