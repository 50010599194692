import { createAsyncThunk } from "@reduxjs/toolkit";
import { TOKEN_NAME } from "../../../../helper/constants";
import { LoaderActions } from "../../componentSlice/loader";
import { deleteAllCookies, setCookie } from "../../../../helper/utils";
import { isDevEnv } from "../../../../helper/utils";
import apiService from "../../../../services/apiService";

const USER_FLOW_SLICE_NAME = "userFlow";
const userThunkActions = {
  sendOtp: createAsyncThunk(
    `${USER_FLOW_SLICE_NAME}/sendOtp`,
    async (mobileNo, { rejectWithValue, dispatch }) => {
      try {
        const apiResponse = await apiService.sendOtp({
          mobile: mobileNo,
        });
        const response = JSON.parse(apiResponse?.data)?.data || {};
        return response || {};
      } catch (err) {
        dispatch(LoaderActions.hideLoader({ show: false }));
        return rejectWithValue("Login failed! Please try again");
      }
    }
  ),
  verifyOtp: createAsyncThunk(
    `${USER_FLOW_SLICE_NAME}/otp/verify`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const apiResponse = await apiService.verifyOtp(body);
        const response = JSON.parse(apiResponse?.data) || {};
        deleteAllCookies();
        if (isDevEnv()) {
          setCookie(TOKEN_NAME, apiResponse.headers.authorization, 1, "/");
        }
        dispatch(LoaderActions.hideLoader({ show: false }));
        return response || {};
      } catch (err) {
        dispatch(LoaderActions.hideLoader({ show: false }));
        return rejectWithValue("OTP Verification Failed");
      }
    }
  ),
};

export default userThunkActions;
