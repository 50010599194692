import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../../../../services/apiService/api/beatApi";
import { BEAT_FLOW_SLICE_NAME } from "../../sliceConstants";

const beatThunkActions = {
  getRetailers: createAsyncThunk(
    `${BEAT_FLOW_SLICE_NAME}/getRetailers`,
    async (agentId, { rejectWithValue }) => {
      try {
        const res = await apiService.getRetailers(agentId);
        return res || {};
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  ),
};

export default beatThunkActions;
